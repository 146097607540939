import * as React from "react"

import theme from "../assets/theme"
import {Grid, Typography, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {useState} from "react";

import AnastasiiaImage from "../assets/anastasiia_img.jpeg"
import AndiImage from "../assets/andi_image.jpeg"
import BenImage from "../assets/ben_image.jpg"
import SandroImage from "../assets/sandro_img.jpg"
import MarinaImage from "../assets/marina_image.jpeg"
import Layout from "../components/layout"
import TeamList from "../components/Team/TeamList"

const MEMBERS = [
  {
    name: "Benjamin Roth",
    src: BenImage,
    description: (
      <ul>
        <li>Scientific Advisor</li>
        <li>Professor of Computer Science and Digital Philology</li>
        <li>
          Research Group Data Mining and Machine Learning
        </li>
        <li>University of Vienna</li>
      </ul>
    ),
  },
  {
    name: "Anastasiia Sedova",
    src: AnastasiiaImage,
    description: (
      <ul>
        <li>Lead developer</li>
        <li>PHD Student </li>
        <li>
          Research Group Data Mining and Machine Learning
        </li>
        <li>University of Vienna</li>
      </ul>
    ),
  },
  {
    name: "Andreas Stephan",
    src: AndiImage,
    description: (
      <ul>
        <li>Developer</li>
        <li>PHD Student </li>
        <li>
          Research Group Data Mining and Machine Learning
        </li>
        <li>University of Vienna</li>
      </ul>
    ),
  },
  {
    name: "Marina Speranskaya",
    src: MarinaImage,
    description: (
      <ul>
        <li>Contributor </li>
        <li>PHD Student</li>
        <li>Center for Information and Language Processing (CIS)</li>
        <li>Ludwig-Maximilians-Universität München</li>
      </ul>
    ),
  },
  {
    name: "Alessandro Volpicella",
    src: SandroImage,
    description: (
      <ul>
        <li>Contributor </li>
        <li>M.Sc. Computer Science</li>
        <li>Faculty of Computer Science</li>
        <li>Ludwig-Maximilians-Universität München</li>
      </ul>
    ),
  },
]

const local_theme = createTheme(theme, {
  root: {
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  logo: {
    width: "12em",
    height: "4em",
    marginLeft: "2vw",
    marginBottom: "1vw",
    cursor: "pointer",
  },
  navbar: { paddingTop: "2%" },
  navbarItem: { paddingRight: "5%" },
  navBarItems: {},
  title: {
    marginBottom: "8vh",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
  },
})

const AboutUs = ({ data, location }) => {
  const [getMembers, setMembers] = useState(MEMBERS)

  console.log({ MEMBERS })

  // useEffect(() => {
  //   const sortedMembers = MEMBERS.sort((a, b) =>
  //     a.name.split(" ")[1].localeCompare(b.name.split(" ")[1])
  //   )
  //   setMembers(sortedMembers)
  // }, [MEMBERS])

  return (
    <ThemeProvider theme={local_theme}>
      <Layout>
        <Grid container sx={local_theme.root}>
          <Grid container item xs={12} spacing={2}>
            <Grid item>
              <Typography variant="h3">About Us</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Knodle is a Python framework for improved weakly supervised deep learning.
                The framework is developed within the WWTF Vienna Research Group project Knowledge-infused Deep Learning for Natural Language Processing at the Unversity of Vienna, lead by Benjamin
                Roth.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TeamList team={getMembers} />
          </Grid>
        </Grid>
      </Layout>
    </ThemeProvider>
  )
}

export default AboutUs
