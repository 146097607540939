import * as React from "react"
import {Divider, Typography} from "@mui/material";
import {Card, CardContent, CardMedia} from "@mui/material";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import theme from "../../assets/theme";

const item_theme = createTheme(theme, {
    root: {
        marginTop: "10%",
        height: "40em",
        width: "25em",
        maxWidth: "80%",
        [theme.breakpoints.down("sm")]: {
            maxWidth: "91%",
        },
    },
    avatar: {
        marginTop: "20px",
        width: "12em",
        height: "12em",
        margin: 0,
    },
    description: {
        maxWidth: "80%",
        padding: "1%",
    },
    media: {
        objectFit: "cover",
        objectPosition: "top",
        height: "50%",
    },
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    divider: {
        height: 1,
        width: "75%",
        backgroundColor: theme.palette.primary.main,
        alignSelf: "center",
        margin: "5% 0px",
    },
})

const TeamListItem = ({src, name, description}) => {

    return (
        <ThemeProvider theme={item_theme}>
            <Card sx={item_theme.root}>
                <CardMedia
                    sx={item_theme.media}
                    image={src}
                    title={name}
                    component="img"
                />
                <CardContent sx={item_theme.content}>
                    <Typography variant="h5" component="h2">
                        {name}
                    </Typography>
                    <Divider flexItem sx={item_theme.divider}/>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        sx={item_theme.description}
                    >
                        {description}
                    </Typography>
                    <br/>
                </CardContent>
            </Card>
        </ThemeProvider>
    )
}

export default TeamListItem
