import * as React from "react"
import {Grid, Divider} from "@mui/material";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import TeamListItem from "./TeamListItem"

const theme = createTheme({
    avatar: {
        marginTop: "20px",
        width: "12em",
        height: "12em",
        margin: 0,
    },
})

const TeamList = ({team}) => {

    return (
        <ThemeProvider theme={theme}>
            <Grid
                item
                xs={12}
                container
                justify="center"
                alignItems="center"
                direction="row"
                sx={theme.root}
            >
                <Grid container item style={{marginBottom: "10%"}}>
                    {team.map((item, index) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            container
                            alignItems="center"
                            justify="center"
                            key={index}
                        >
                            <TeamListItem {...item} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default TeamList
